













































































import {
  Component, PropSync, Vue, Watch,
} from 'vue-property-decorator';

import FlagshipProfilingModalViewModel
  from '@/vue-app/view-models/components/flagship/flagship-profiling/flagship-profiling-modal-view-model';

@Component({
  name: 'FlagshipProfilingModalSteps',
  components: {
    FlagshipProfilingStepIntroduction: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepIntroduction.vue'),
    FlagshipProfilingStepIntroductionVideo: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepIntroductionVideo.vue'),
    FlagshipProfilingStepGetReady: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepGetReady.vue'),
    FlagshipProfilingStepEconomicDependents: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepEconomicDependents.vue'),
    FlagshipProfilingStepEmploymentSituation: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepEmploymentSituation.vue'),
    FlagshipProfilingStepBudgetVideo: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepBudgetVideo.vue'),
    FlagshipProfilingStepFinancialHabits: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepFinancialHabits.vue'),
    FlagshipProfilingStepGoals: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepGoals.vue'),
    FlagshipProfilingStepFinancialFreedom: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepFinancialFreedom.vue'),
  },
})
export default class FlagshipProfilingModalSteps extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  flagship_modal_view_model = Vue.observable(
    new FlagshipProfilingModalViewModel(),
  );

  @Watch('synced_is_open')
  syncedIsOpenChange(synced_is_open: boolean) {
    if (synced_is_open) {
      this.flagship_modal_view_model.initialize();
    }
  }

  endProcess(recommended_investment_product_id: string) {
    this.synced_is_open = false;
    this.flagship_modal_view_model.endProcess();
    this.$emit('endProcess', recommended_investment_product_id);
  }

  closeModal() {
    this.synced_is_open = false;
  }

  createGoals(creation_goal_type: string) {
    this.synced_is_open = false;
    this.$emit('createGoals', creation_goal_type);
  }
}
