import TYPES from '@/types';

// Application
import UpdateInvestorProfileCommand from '@/modules/flagship/investor-profile/investor-profile/application/commands/update-investor-profile-command';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class FlagshipProfilingModalViewModel {
  readonly i18n_namespace = 'components.flagship.flagship-profiling';

  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_COMMAND)
  private readonly update_investor_profile_command!: UpdateInvestorProfileCommand;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  is_loading = false;

  private readonly STEPS = [
    'FlagshipProfilingStepIntroduction',
    'FlagshipProfilingStepIntroductionVideo',
    'FlagshipProfilingStepGetReady',
    'FlagshipProfilingStepEconomicDependents',
    'FlagshipProfilingStepEmploymentSituation',
    'FlagshipProfilingStepBudgetVideo',
    'FlagshipProfilingStepFinancialHabits',
    'FlagshipProfilingStepGoals',
    'FlagshipProfilingStepFinancialFreedom',
  ];

  current_step = 0;

  current_component = this.STEPS[this.current_step];

  investor_profile_id = '';

  last_step_flow_one_position = -1;

  recommended_investment_product = '';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  loadInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute();
      this.investor_profile_id = investor_profile.id;
      if (investor_profile.last_step_flow_one) {
        const last_step_founded = this.STEPS.findIndex(
          (step) => step === investor_profile.last_step_flow_one,
        );
        this.last_step_flow_one_position = last_step_founded;
        if (last_step_founded) {
          this.current_step = last_step_founded;
          this.current_component = this.STEPS[this.current_step];
        }
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investor_profile'));
    }
  };

  updateLastStepFlowOne = async () => {
    try {
      if (this.current_step > this.last_step_flow_one_position) {
        const investor_profile_update_data = {
          id: this.investor_profile_id,
          last_step_flow_one: this.STEPS[this.current_step],
        };
        await this.update_investor_profile_command.execute(investor_profile_update_data);
        this.last_step_flow_one_position = this.current_step;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_last_step_flow_one'));
    }
  }

  nextStep = async () => {
    if (this.current_step < this.STEPS.length) {
      this.current_step += 1;
      await this.updateLastStepFlowOne();
      this.current_component = this.STEPS[this.current_step];
    }
  }

  prevStep = () => {
    if (this.current_step > 0) {
      this.current_step -= 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  endProcess= () => {
    this.current_step = 0;
    this.current_component = this.STEPS[this.current_step];
  }

  skip = () => {
    window.location.href = '/goals-dashboard';
  }

  changeLoadingStep = (loading: boolean) => {
    this.is_loading = loading;
  }

  getActiveClass = (index: number) => {
    const inactive_class = 'dark-grey';
    const active_class = 'secondary';
    if (this.current_step <= 2) {
      return inactive_class;
    }
    if (index === 0 && this.current_step >= 3) {
      return active_class;
    }
    if (index === 1 && this.current_step >= 4) {
      return active_class;
    }
    if (index === 2 && this.current_step >= 5) {
      return active_class;
    }
    if (index === 3 && this.current_step >= 7) {
      return active_class;
    }
    if (index === 4 && this.current_step >= 8) {
      return active_class;
    }
    if (index === 5 && this.current_step >= 10) {
      return active_class;
    }
    return inactive_class;
  }

  initialize = async () => {
    this.is_loading = true;
    await this.loadInvestorProfile();
    this.is_loading = false;
  }
}
